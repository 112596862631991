import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './characters.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Col, Row, Table } from 'react-bootstrap';
import { ZZZCharStatsFullJSON } from '../../modules/zzz/common/data/zzz-char-stats-data-max';
import { faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ZZZCharacter } from '../../modules/zzz/common/components/zzz-character';

const ZZZCharStats: React.FC = () => {
  const [sortOption, setSortOption] = useState('name');
  const [listChars, setListChars] = useState(ZZZCharStatsFullJSON);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (sortOption === 'name') {
      setListChars(listChars.sort((a, b) => (a.slug > b.slug ? 1 : -1)));
    } else if (sortOption === 'atk') {
      setListChars(listChars.sort((a, b) => (a.atk < b.atk ? 1 : -1)));
    } else if (sortOption === 'hp') {
      setListChars(listChars.sort((a, b) => (a.hp < b.hp ? 1 : -1)));
    } else if (sortOption === 'def') {
      setListChars(listChars.sort((a, b) => (a.def < b.def ? 1 : -1)));
    } else if (sortOption === 'crit') {
      setListChars(
        listChars.sort((a, b) => (a.critRate < b.critRate ? 1 : -1))
      );
    } else if (sortOption === 'pen') {
      setListChars(
        listChars.sort((a, b) => (a.penRatio < b.penRatio ? 1 : -1))
      );
    } else if (sortOption === 'impact') {
      setListChars(listChars.sort((a, b) => (a.impact < b.impact ? 1 : -1)));
    } else if (sortOption === 'mastery') {
      setListChars(
        listChars.sort((a, b) => (a.ano_mastery < b.ano_mastery ? 1 : -1))
      );
    } else if (sortOption === 'energy') {
      setListChars(
        listChars.sort((a, b) => (a.energyRegen < b.energyRegen ? 1 : -1))
      );
    } else if (sortOption === 'crit_dmg') {
      setListChars(listChars.sort((a, b) => (a.critDmg < b.critDmg ? 1 : -1)));
    }
    forceUpdate();
  }, [sortOption]);
  return (
    <DashboardLayout className={'generic-page characters-zzz '} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters (Agents) Stats</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/zzz/categories/category_stats.png"
            alt="Character Stats"
          />
        </div>
        <div className="page-details">
          <h1>Zenless Zone Zero Characters (Agents) Stats</h1>
          <h2>
            Compare the stats of all Zenless Zone Zero characters (agents).
          </h2>
          <p>
            Last updated: <strong>11/02/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Characters Stats (level 60)" />
      <div className="character-stats-zzz">
        <Row>
          <Col>
            <Table className="stats-table" striped bordered responsive>
              <thead>
                <tr>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'name' && 'current'}`}
                    onClick={() => setSortOption('name')}
                  >
                    NAME
                    {sortOption && sortOption === 'name' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'hp' && 'current'}`}
                    onClick={() => setSortOption('hp')}
                  >
                    HP
                    {sortOption && sortOption === 'hp' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'def' && 'current'}`}
                    onClick={() => setSortOption('def')}
                  >
                    DEF
                    {sortOption && sortOption === 'def' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'atk' && 'current'}`}
                    onClick={() => setSortOption('atk')}
                  >
                    ATK
                    {sortOption && sortOption === 'atk' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'crit' && 'current'}`}
                    onClick={() => setSortOption('crit')}
                  >
                    CRIT Rate
                    {sortOption && sortOption === 'crit' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'crit_dmg' && 'current'}`}
                    onClick={() => setSortOption('crit_dmg')}
                  >
                    CRIT DMG
                    {sortOption && sortOption === 'crit_dmg' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'pen' && 'current'}`}
                    onClick={() => setSortOption('pen')}
                  >
                    PEN Ratio
                    {sortOption && sortOption === 'pen' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'impact' && 'current'}`}
                    onClick={() => setSortOption('impact')}
                  >
                    Impact
                    {sortOption && sortOption === 'impact' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'mastery' && 'current'}`}
                    onClick={() => setSortOption('mastery')}
                  >
                    Atr. Mastery
                    {sortOption && sortOption === 'mastery' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'energy' && 'current'}`}
                    onClick={() => setSortOption('energy')}
                  >
                    Energy
                    {sortOption && sortOption === 'energy' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {listChars.map((char, index) => {
                  return (
                    <tr key={index}>
                      <td scope="row" className="char">
                        <ZZZCharacter
                          slug={char.character}
                          mode="icon"
                          enablePopover
                        />
                        <span className="name">{char.slug}</span>
                      </td>
                      <td className="stat">{char.hp.toFixed(0)}</td>
                      <td className="stat">{char.def.toFixed(0)}</td>
                      <td className="stat">{char.atk.toFixed(0)}</td>
                      <td className="stat">{char.critRate.toFixed(2)}%</td>
                      <td className="stat">{char.critDmg.toFixed(2)}%</td>
                      <td className="stat">{char.penRatio.toFixed(0)}%</td>
                      <td className="stat">{char.impact.toFixed(0)}</td>
                      <td className="stat">{char.ano_mastery.toFixed(0)}</td>
                      <td className="stat">{char.energyRegen}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        {/* <div className="zzz-stats-table">
          <div className="header"></div>
          <div className="inside">
            {listChars.map((char, index) => {
              return (
                <div className="single-row" key={index}>
                  <div className="char">
                    <ZZZCharacter
                      slug={char.character}
                      mode="icon"
                      enablePopover
                    />
                    <span className="name">{char.slug}</span>
                  </div>
                  <div className="stat">{char.hp.toFixed(0)}</div>
                  <div className="stat">{char.def.toFixed(0)}</div>
                  <div className="stat">{char.atk.toFixed(0)}</div>
                  <div className="stat">{char.critRate.toFixed(2)}%</div>
                  <div className="stat">{char.critDmg.toFixed(2)}%</div>
                  <div className="stat">{char.penRatio.toFixed(0)}%</div>
                  <div className="stat">{char.impact.toFixed(0)}</div>
                  <div className="stat">{char.ano_mastery.toFixed(0)}</div>
                  <div className="stat">{char.energyRegen}</div>
                </div>
              );
            })}
          </div>
        </div> */}
      </div>
    </DashboardLayout>
  );
};

export default ZZZCharStats;

export const Head: React.FC = () => (
  <Seo
    title="Characters (Agents) Stats | Zenless Zone Zero | Prydwen Institute"
    description="Compare the stats of all Zenless Zone Zero characters (Agents)."
    game="zzz"
  />
);
